import { FC, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, GridItem, SearchBox, AdminSessionLogoutSvg, EditStepSvg, DeleteSvg, AdminAPIIcon, AdminDesktopIcon, AdminSAPIcon, AdminWebIcon, AdminDatabaseIcon, SortCenterIconSvg, AddCircleIconSvg, Button,FilterFilledIconSvg, FilterIconSvg } from "../../global";
import CONST from "../../locale.json";
import './TableView.scss';
import { RootState } from "../../reducer";
import { CALL_NOTIFY, REFRESH_TREEDATA } from "../../global/store/action";
import { ClickAwayListener } from "@mui/material";
interface TableViewInterface {
    Title: string,
    onClickEdit?: (e: any) => void,
    onClickDelete?: (e: any) => void,
    onClickLogout?: (e: any) => void,
    onClickRefresh?: () => void,
    showSLNo?: boolean,
    header: string[];
    tableData: any,
    screenBlock?: boolean,
    filter: string,
    sessionTime?: any,
    searchPlaceHolder?: string
    filterParams?: string[]
}
const pass = [200, 202, 307, 204];
const months = CONST.months;
export const TableView: FC<TableViewInterface> = ({ tableData, onClickDelete, Title, onClickLogout, onClickEdit, onClickRefresh, showSLNo = false, header, filter, sessionTime, searchPlaceHolder, filterParams }) => {
    const uName = useSelector((state: RootState) => state.adminReducer.userName);
    const [filterText, setFilterText] = useState<string>('');
    const [searchText, setSearchText] = useState<string>('')
    const [data, setData] = useState<any>();
    const [lastUpdate, setLastUpdate] = useState<string>("")
    const [nextupdate, setNextUpdate] = useState<string>("")
    const [showFilter, setShowFilter] = useState<boolean>(false)
    const [filterContent, setFilterContent] = useState<string[]>(filterParams as string[])
    const [ASC, setASC] = useState<any>({ 'username': { asc: false }, 'login time': { asc: false }, 'microservices': { asc: false }, 'status': { asc: false } });
    const dispatch = useDispatch();
    const [selectedStatus, setSelectedStatus] = useState<string[]>(filterContent);
    const [filterStatus, setFilterStatus] = useState<boolean>(false)
    const refreshtreeviewer = useSelector((state: RootState) => state.globalReducer.refreshTreeData)


    const toggleStatus = (item: string) => {

        if (item === "All") {
            if (selectedStatus.includes("All")) {
                setSelectedStatus([])
            }
            else {
                setFilterStatus(false)
                setSelectedStatus(filterContent)
            }
        }
        else {
            setFilterStatus(true)
            const updatedStatus = selectedStatus.includes(item)
                ? selectedStatus.filter(status => status !== item && status !== "All")
                : [...selectedStatus, item];

            const allOtherSelected = filterContent.every(status =>
                status === "All" || updatedStatus.includes(status)
            );
            setSelectedStatus(allOtherSelected ? [...updatedStatus, "All"] : updatedStatus);
        }

    };

    useEffect(() => {
        setFilterText('')
        setSearchText('')
    }, [refreshtreeviewer])

    useEffect(() => {
        setData(
            tableData?.filter((p: Record<string, any>) => p[filter]?.toLowerCase().includes(filterText?.toLowerCase()))
        )
        if (sessionTime) {
            let nextUpdateTime = new Date(sessionTime);
            nextUpdateTime.setHours(nextUpdateTime.getHours() + 1);
            setLastUpdate((processDate(sessionTime) + ", " + processTime(sessionTime)).toString());
            setNextUpdate((processDate(sessionTime) + ", " + processTime(nextUpdateTime.toString())).toString());
        }
    }, [filterText, tableData, sessionTime])

    useEffect(() => {
        setFilterContent(filterParams?.filter(params => params.toLowerCase().includes(searchText.toLowerCase())) as string[])

    }, [searchText])

    const getTargetIcon = useCallback((app: string) => ({
        Web: AdminWebIcon,
        SAP: AdminSAPIcon,
        API: AdminAPIIcon,
        Database: AdminDatabaseIcon,
        Desktop: AdminDesktopIcon
    }[app]), []);

    const onDelete = (e: any) => {
        if (onClickDelete) {
            onClickDelete(e);
        }
    }

    const onEdit = (e: any) => {
        if (onClickEdit) {
            onClickEdit(e);
        }
    }

    const Logout = (e: any) => {
        if (onClickLogout) {
            onClickLogout(e);
        }
    }

    const onRefresh = () => {
        if (onClickRefresh) {
            onClickRefresh();
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Page refreshed successfully" } })
        }
    }

    const sortByDate = (arr: any, mul: number) => {
        const sorter = (a: any, b: any) => {
            return mul * (new Date(a.start).getTime() - new Date(b.start).getTime());
        }
        arr.sort(sorter);
        setData(arr);
    };

    const sortResults = (target: string, sortField: string, pageTitle: string) => {
        if ((pageTitle === 'Client health' || pageTitle ==='User session') && sortField === 'username') sortField = 'userName';
        let sort = ASC[sortField.toLowerCase()].asc;
        if (target === "MS") {
            if (sort) {
                setData(
                    data.sort(function (a: any, b: any) { return (a[sortField].toLocaleLowerCase() > b[sortField].toLocaleLowerCase()) ? 1 : ((b[sortField].toLocaleLowerCase() > a[sortField].toLocaleLowerCase()) ? -1 : 0); }));
            }
            else {
                setData(
                    data.sort(function (a: any, b: any) { return (a[sortField].toLocaleLowerCase() < b[sortField].toLocaleLowerCase()) ? 1 : ((b[sortField].toLocaleLowerCase() < a[sortField].toLocaleLowerCase()) ? -1 : 0); }));
            }
        }
        else {
            const mul = (sort) ? -1 : 1;
            sortByDate(data, mul);
        }
        setASC((prevState: any) => ({ ...prevState, [sortField.toLowerCase()]: { asc: !sort } }));
    }

    const processDate = (d: string): string => {
        const date = new Date(TimeFormat(d))
        const dt = [date.getDate(), date.getMonth(), date.getFullYear()];
        return `${("0" + dt[0]).slice(-2)} ${months[+dt[1]]} ${dt[2]}`;
    };

    const processTime = (t: string): string => {
        const time = new Date(TimeFormat(t))
        const nt = [time.getHours(), time.getMinutes()];
        return `${("0" + ((+nt[0] % 12) || 12)).slice(-2)} : ${("0" + nt[1]).slice(-2)} ${(+nt[0] < 12) ? "AM" : "PM"}`;
    };

    function applyFilter() {

        setData(tableData?.filter((p: any) => selectedStatus.includes(p.status)))
        setShowFilter(false)
    }


    const TimeFormat = (time: any) => {
        var dateUTC = new Date(time);
        var dateIST = new Date(dateUTC.getTime());
        //date shifting for IST timezone (+5 hours and 30 minutes)
        dateIST.setHours(dateIST.getHours());
        dateIST.setMinutes(dateIST.getMinutes());
        return dateIST;
    }
    return (
        <div className="table-container">
            <div className="table-heading">
                <h3 className={"dds-h5-lm"}>{Title}</h3>
                <SearchBox className="--search-card" value={filterText} onChange={setFilterText} placeholder={searchPlaceHolder ? searchPlaceHolder : "Search"} />
            </div>
            <Grid className={`table-header dds-label-semibold ${data?.length === 0 ? "apply-border-bottom" : ""}`}>
                {showSLNo ? <GridItem xs={Title === "Edit project" ? 0.8 : Title === "Assign/unassign users" ? 0.47 : 1} className="table-content">S.No.</GridItem> : null}
                {header.map((e) => (
                    <>
                        {e !== 'id' ? <GridItem xs={e === "Username" && Title == 'Client health' ? 1.45 : e === "Microservices" ? 1.8 : (Title === "Edit project" && e === "Project") ? 6.8 : (Title === "Assign/unassign users" && e === "Project") ? 5 : (Title === "Edit role" && e === "Role") ? 3 : (Title === "Assign/unassign users" && e === "Users") ? 0.28 : (Title === "Edit role" && e === "Scope") ? 3 : (Title === "Edit role" && e === "Scope") ? 3 : 1} className="table-content"><span style={{ marginLeft: e == "App type" ? "8px" : "" }}>{e}</span>

                            {(Title === "Microservice health" || Title === 'User session' || Title === 'Client health') && (e === 'Microservices' || e === 'Username' || e === 'Login time' || e === 'Status') ?
                                <img src={(Title === "Client health" && e === "Status") ? (filterStatus ? FilterFilledIconSvg : FilterIconSvg) : SortCenterIconSvg} alt="sort" onClick={_e => (e === 'Microservices' || e === 'Username' || (e === 'Status' && Title !== "Client health")) ? sortResults("MS", e.toLowerCase(), Title) : (Title === "Client health" && e === "Status") ? setShowFilter(true) : sortResults("time", e.toLowerCase(), Title)}
                                    className={(Title === "Client health" && e === "Status") ? '' : `--step-row-action ${ASC[e.toLowerCase()].asc ? "rotate180" : ""}`}
                                    title={(Title === "Client health" && e === "Status") ? 'Filter' : `Sort ${ASC[e.toLowerCase()].asc ? "Descending" : "Ascending"}`}
                                    style={{ width: e === 'Username' ? "7%" : (e === "Status") ? "13%" : "9.5%", marginLeft: '3%' }}
                                />
                                : null}</GridItem> : null}

                    </>)
                )}
                {onClickEdit ? <GridItem xs={1} className="table-content" style={{ justifyContent: 'center' }}>Edit</GridItem> : null}
                {onClickDelete ? <GridItem xs={1} className="table-content" style={{ justifyContent: 'center' }}>Delete</GridItem> : null}
                {onClickLogout ? <GridItem xs={1} className="table-content" style={{ justifyContent: 'center' }}>Log out</GridItem> : null}
            </Grid>
            {showFilter && (
                <ClickAwayListener onClickAway={() => setShowFilter(false)}>
                    <div className="StatusFilter">
                        <div className="scheduled--filter-dropdown">
                            <div className="filterBox">
                                <div className="title">
                                    Filter
                                </div>
                                <div className="searchHolder">
                                    <SearchBox className="searchBox" value={filterText} onChange={setSearchText} placeholder={"Search"} />
                                </div>

                                <div className="filterContent">
                                    {filterContent.map((value, index) => (
                                        <label key={index} ><input type="checkbox" onClick={() => toggleStatus(value)} readOnly checked={selectedStatus.includes(value)} />{value}</label>
                                    )

                                    )}

                                </div>

                                <div className="filterFooter">
                                    <button className="cancel-btn" onClick={() => { setShowFilter(false) }}>Cancel</button><button className="apply-btn" onClick={applyFilter} >Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ClickAwayListener>
            )}
            <div className="table-body dds-link">
                {data?.map((e: any, i: number) => (
                    <Grid className={`table-content dds-link ${i === 0 ? "apply-border-top" : ""}`}>
                        {showSLNo ? <GridItem xs={Title === "Edit project" ? 0.8 : Title === "Assign/unassign users" ? 0.41 : 1} className="table-data"><span>{i + 1}</span></GridItem> : null}
                        {
                            Object.entries(e)?.map((val: any) => (val[0] !== 'id' ? !(val[0] === 'appType') ?
                                <GridItem xs={(val[0].toLowerCase() == "username" && Title == 'Client health') ? 1.45 : (val[0].toLowerCase() == "name" && Title == 'Edit project') ? 6.8 : (val[0].toLowerCase() == "name" && Title == 'Assign/unassign users') ? 5 : (val[0].toLowerCase() == "users" && Title == 'Assign/unassign users') ? 0.22 : (val[0].toLowerCase() == "name" && Title == 'Edit role') ? 3 : (val[0].toLowerCase() == "scope" && Title == 'Edit role') ? 3 : (val[0].toLowerCase() == "microservice") ? 1.8 : 1} className={(val[0] === 'statusCode' || val[0] === 'statusText' || val[0] === "status") ? (pass.includes(val[1]) || (val[1] === "OK") || (val[1] === "Active")) ? "table-data --pass" : "table-data --fail" : "table-data"} title={val[1]}><span>{val[1]}</span></GridItem> :
                                <GridItem xs={1} className="table-data"><img style={{
                                    width: '50%', height: '50%'
                                }} src={getTargetIcon(val[1])}></img>
                                </GridItem> : null))
                        }
                        {onClickEdit ? <GridItem xs style={{ justifyContent: 'center' }} className={((Title === "Role list") && (e.name === "admin" || e.name === "user")) || (Title === "Edit user" && (e.userName === "admin")) ? "table-data disable-edit" : "table-data"}><img style={{ marginLeft: 2 }} src={EditStepSvg} onClick={() => onEdit(e)}></img></GridItem> : null}
                        {onClickDelete ? <GridItem xs style={{ justifyContent: 'center' }} className={((Title === "Role list") && (e.name === "admin" || e.name === "user")) || (Title === "Edit user" && ((e.roleID === "admin" && e.userName === localStorage.getItem('userName')))) ? "table-data disable-edit" : "table-data"}>< img style={{ marginLeft: 2 }} src={DeleteSvg} onClick={() => onDelete(e)}></img></GridItem> : null}
                        {onClickLogout ? <GridItem xs style={{ justifyContent: 'center' }} className="table-data"><img style={{ marginLeft: 8 }} src={AdminSessionLogoutSvg} onClick={() => Logout(e)}></img></GridItem> : null}
                    </Grid>
                )
                )}
            </div>
            {
                onClickRefresh ?
                    <div className="microservice-update-container">
                        <Button title="Refresh" className="--savebtn" onClick={() => onRefresh()} disabled={false}></Button>
                    </div>
                    : null
            }


        </div >
    )

}